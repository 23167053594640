import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import CardPricing from "./CardPricing";

import { plans } from "../fixtures/plans";
import { setSelectedPlan } from "../../reducers/plan/planSlice";

const Pricing = () => {
    const [frecuency, setFrecuency] = useState("MONTHLY");
    const dispatch = useDispatch();
    const history = useHistory();

    const handleSelectedPlan = (plan) => {
        dispatch(setSelectedPlan(plan));
        history.push("/register-client");
        window.scrollTo(0, 0);
    }

    return (
        <div className="container-fluid bg-light-purple p-4 mt-3" id="pricing">
            <div className="container-fluid bg-white p-3 rounded-5">
                <div className="row">
                    <div className="col abs-center">
                        <div className="bg-green px-3 py-1 rounded-3 text-white text-center mx-auto">
                            Precios en COP
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col abs-center">
                        <button
                            onClick={() => setFrecuency("MONTHLY")}
                            className={`btn mx-1 ${frecuency === "MONTHLY" ? "btn-warning" : "bg-light-green"}`}
                        >
                            Mensual
                        </button>
                        <button
                            onClick={() => setFrecuency("ANUAL")}
                            className={`btn mx-1 ${frecuency === "ANUAL" ? "btn-warning" : "bg-light-green"}`}
                        >
                            Anual
                        </button>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col items-pricing">
                        {
                            plans.filter(item => item.frecuency === frecuency).map(item =>
                                <CardPricing
                                    key={item.id}
                                    {...item}
                                    onSelected={() => handleSelectedPlan(item)}
                                />)
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Pricing;