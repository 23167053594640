import { useState } from "react";
import { useForm } from "react-hook-form";

//import { registerRequestClient } from "../../firebase/firebaseDatabase";

export const ContactUs = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [resultSending, setResultSending] = useState({
    show: false,
    message: "",
    ok: true,
  });

  /*     const onSubmit = async (data) => {
        const res = await registerRequestClient(data);
        if (res.ok) reset();
        setResultSending({ ...res, show: true });
    } */
  const onSubmit = async (data) => {
    const apiEndpoint = "https://api.web3forms.com/submit";
    try {
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          access_key: process.env.REACT_APP_ACCESS_KEY,
          subject: `Nuevo mensaje de contacto de ${data.name}`,
          from_name: "Formulario de contacto Sortom",
          replyto: data.email,
          ...data,
        }),
      });

      const result = await response.json();
      if (result.success) {
        setResultSending({
          show: true,
          message: "Mensaje enviado con éxito",
          ok: true,
        });
        reset();
      } else {
        setResultSending({
          show: true,
          message: "Error al enviar el mensaje",
          ok: false,
        });
      }
    } catch (error) {
      setResultSending({
        show: true,
        message: "Error al enviar el mensaje",
        ok: false,
      });
    }
  };

  return (
    <div id="contact-us">
      <div className="row mt-4 bg-light-green pt-3" />
      <div className="row bg-green-200 pt-3" />
      <div className="row bg-green-300 pt-3" />
      <div className="row">
        <div className="col-10 mx-auto">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row px-5 py-4 font-lato">
              <div className="col-12 col-lg-6">
                <h1 className="font-teko fs-large text-green">
                  SIEMPRE ESTAMOS AQUÍ PARA AYUDARTE.
                </h1>

                <p>
                  Tu empresa en las mejores manos, potencia hoy mismo tu
                  negocio.
                </p>
                <div className="card-contact">
                  <div className="circle bg-green">
                    <i className="fa-solid fa-location-dot fs-4"></i>
                  </div>
                  <div className="information">
                    <div className="font-teko fs-5">Vísitanos</div>
                    <div className="content">
                      Calle 46 Nro. 16-24 oficina 708 Torre Empresarial San Juan
                      Plaza
                    </div>
                  </div>
                </div>

                <div className="card-contact">
                  <div className="circle bg-green">
                    <i className="fa-solid fa-envelope fs-4"></i>
                  </div>
                  <div className="information">
                    <div className="font-teko fs-5">Dirección de email</div>
                    <div className="content">
                      <a href="mailto:sortomoficial@gmail.com">
                        sortomoficial@gmail.com
                      </a>
                    </div>
                  </div>
                </div>

                <div className="card-contact">
                  <div className="circle bg-green">
                    <i className="fa-solid fa-phone-volume fs-4"></i>
                  </div>
                  <div className="information">
                    <div className="font-teko fs-5">Llámanos</div>
                    <div className="content">
                      <a className="text-green" href="tel:+573133866002">
                        313 386 6002
                      </a>
                    </div>
                  </div>
                </div>

                <div className="card-contact">
                  <div className="circle bg-green">
                    <i className="fa-brands fa-whatsapp fs-4"></i>
                  </div>
                  <div className="information">
                    <div className="font-teko fs-5">
                      Contáctanos por WhatsApp
                    </div>
                    <div className="content">
                      <a
                        className="text-green"
                        href="https://wa.me/573133866002"
                      >
                        Entra aquí
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                {resultSending.show && (
                  <div className="row mt-4">
                    <div className="col">
                      <div
                        className={`d-block rounded-pill alert px-3 py-1 ${
                          resultSending.ok ? "alert-success" : "alert-danger"
                        }`}
                      >
                        <i
                          className={`fa-solid ${
                            resultSending.ok
                              ? "fa-circle-check"
                              : "fa-circle-xmark"
                          } me-2`}
                        ></i>
                        {resultSending.message}
                      </div>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col">
                    <label className="font-lato">Nombre</label>
                    <input
                      type="text"
                      className="form-control green-input rounded-pill"
                      {...register("name", {
                        required: "Nombre es requerido",
                        minLength: 3,
                        maxLength: 60,
                        pattern: {
                          value: /^([a-zA-ZÀ-ÿ\u00f1\u00d1\s]{3,60})$/,
                          message: "El formato no es correcto",
                        },
                      })}
                      placeholder="Tu nombre"
                    />
                    {errors.name && (
                      <div className="fs-7 text-red text-end">
                        {errors.name.message}
                      </div>
                    )}
                  </div>
                  <div className="col">
                    <label className="font-lato">Email</label>
                    <input
                      type="email"
                      className="form-control green-input rounded-pill"
                      {...register("email", {
                        required: "Correo requerido",
                        minLength: 3,
                        maxLength: 100,
                        pattern: {
                          value:
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                          message: "El formato no es correcto",
                        },
                      })}
                      placeholder="Tu email"
                    />
                    {errors.email && (
                      <div className="fs-7 text-red text-end">
                        {errors.email.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col">
                    <label className="font-lato">Teléfono</label>
                    <input
                      type="text"
                      className="form-control green-input rounded-pill"
                      {...register("phone", {
                        required: "Telefono de contacto es requerido",
                      })}
                      placeholder="Tu teléfono"
                    />
                    {errors.phone && (
                      <div className="fs-7 text-red text-end">
                        {errors.phone.message}
                      </div>
                    )}
                  </div>
                  <div className="col">
                    <label className="font-lato">Asunto</label>
                    <input
                      type="text"
                      className="form-control green-input rounded-pill"
                      {...register("subject", {
                        required: "Asunto requerido",
                        minLength: 3,
                        maxLength: 60,
                      })}
                      placeholder="Asunto"
                    />
                    {errors.subject && (
                      <div className="fs-7 text-red text-end">
                        {errors.subject.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col">
                    <label className="font-lato">Mensaje</label>
                    <textarea
                      className="form-control green-input textarea-style rounded-2"
                      {...register("message", {
                        required: "Descripción es requerida",
                        minLength: {
                          value: 10,
                          message: "Debe tener al menos 10 caracteres",
                        },
                        maxLength: {
                          value: 500,
                          message: "Debe tener como máximo 500 caracteres",
                        },
                      })}
                      placeholder="Tu mensaje"
                    ></textarea>
                    {errors.message && (
                      <div className="fs-7 text-red text-end">
                        {errors.message.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <button
                      type="submit"
                      className="btn btn-success rounded-pill d-block mx-auto"
                    >
                      <i className="fa-solid fa-paper-plane me-2"></i>
                      Enviar mensaje
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
