import Benefits from "./Benefits";
import { ContactUs } from "./ContactUs";
import FrequestQuestions from "./FrequestQuestions";
import Functionalities from "./Funcionalidades";
import Messages from "./Messages";
import Pricing from "./Pricing";
import Start from "./Start";
import StepsCreateDocument from "./StepsCreateDocument";
import ValidSignature from "./ValidSignature";

const Main = () => {
    return (
        <>
            <Start />
            <Benefits />
            <Functionalities />
            <Messages />
            <StepsCreateDocument />
            <ValidSignature />
            <Pricing />
            <ContactUs />
            <FrequestQuestions />
        </>
    )
}

export default Main;