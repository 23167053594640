export const plans = [
    {
        id: 2,
        title: "Freemium",
        frecuency: "MONTHLY",
        bg: "bg-light-purple",
        price: '60.135',
        anualPrice: " 721.624",
        characteristics: [
            {
                name: "1 GB de almacenamiento.",
                isEspecial: false,
            },
            {
                name: "20 documentos generados + 5 firmados.",
                isEspecial: false,
            },
            {
                name: "Soporte básico: Asistencia por correo electrónico con tiempo de respuesta estándar.",
                isEspecial: false,
            },
            {
                name: "1 Usuario.",
                isEspecial: false,
            },
            {
                name: "Acceso limitado a e-books jurídicos/contables para empresas y material educativo.",
                isEspecial: true,
            },
        ],
    },
    {
        id: 3,
        title: "Básico",
        frecuency: "MONTHLY",
        bg: "bg-light-green",
        price: '127.260',
        anualPrice: "1.527.116",
        characteristics: [
            {
                name: "1 GB de almacenamiento.",
                isEspecial: false,
            },
            {
                name: "20 documentos generados + 5 firmados.",
                isEspecial: false,
            },
            {
                name: "Soporte mejorado: Asistencia por correo electrónico con tiempo de respuesta más rápido.",
                isEspecial: false,
            },
            {
                name: "2 Usuarios",
                isEspecial: false,
            },
            {
                name: "Acceso limitado a e-books jurídicos/contables para empresas y material educativo.",
                isEspecial: true,
            },
            {
                name: "Elaboración o revisión de tres documentos legales/contables por mes.",
                isEspecial: true,
            },
        ],
    },
   /*  {
        id: 4,
        title: "Profesional",
        frecuency: "MONTHLY",
        bg: "bg-light-green",
        price: '8.128',
        anualPrice: "97.539",
        characteristics: [
            {
                name: "3 GB de almacenamiento.",
                isEspecial: false,
            },
            {
                name: "100 documentos generados + 30 firmados.",
                isEspecial: false,
            },
            {
                name: "Soporte prioritario: Asistencia prioritaria por correo electrónico y chat en línea.",
                isEspecial: false,
            },
            {
                name: "2 Usuarios",
                isEspecial: false,
            },
            {
                name: "Acceso limitado a e-books jurídicos/contables para empresas y material educativo.",
                isEspecial: true,
            },
            {
                name: "Elaboración o revisión de tres documentos legales/contables por mes.",
                isEspecial: true,
            },
            {
                name: "Consultas virtuales jurídicas o contables ilimitadas.",
                isEspecial: true,
            },
        ],
    }, */
    {
        id: 5,
        title: "Empresarial",
        frecuency: "MONTHLY",
        bg: "bg-light-orange",
        price: '359.204',
        anualPrice: "4.310.453",
        characteristics: [
            {
                name: "5 GB de almacenamiento.",
                isEspecial: false,
            },
            {
                name: "200 documentos generados + 50 firmados.",
                isEspecial: false,
            },
            {
                name: "Soporte premium: Soporte telefónico dedicado y asistencia personalizada.",
                isEspecial: false,
            },
            {
                name: "10 Usuarios.",
                isEspecial: false,
            },
            {
                name: "Acceso limitado a e-books jurídicos/contables para empresas y material educativo.",
                isEspecial: true,
            },
            {
                name: "Elaboración o revisión de cinco documentos legales/contables por mes.",
                isEspecial: true,
            },
            {
                name: "Consultas virtuales jurídicas o contables ilimitadas.",
                isEspecial: true,
            },
            {
                name: "1 asesoría en transformación digital, innovación o protección de datos personas.",
                isEspecial: true,
            },
        ],
    },
    {
        id: 6,
        title: "Básico",
        frecuency: "ANUAL",
        bg: "bg-light-green",
        characteristics: [
            {
                name: "Incluye características plan básico mensual",
                icon: "fa-plus",
                color: "bg-purple",
            },
            {
                name: "Descuento en comparación con la tarifa mensual total (por ejemplo, el precio de 10 meses en lugar de 12).",
                icon: "fa-percent",
                color: "bg-green",
            },
            {
                name: "Acceso a webinars o capacitaciones exclusivas.",
                icon: "fa-check",
                color: "bg-orange",
            },
        ],
    },
    {
        id: 7,
        title: "Profesional",
        frecuency: "ANUAL",
        bg: "bg-light-green",
        characteristics: [
            {
                name: "Incluye características plan profesional mensual",
                icon: "fa-plus",
                color: "bg-purple",
            },
            {
                name: "Descuento significativo comparado con la tarifa mensual total.",
                icon: "fa-percent",
                color: "bg-green",
            },
            {
                name: "Acceso prioritario a nuevas características y actualizaciones. Una sesión de consultoría personalizada para optimizar el uso de SORTOM en la organización.",
                icon: "fa-check",
                color: "bg-orange",
            },
        ],
    },
    {
        id: 8,
        title: "Empresarial",
        frecuency: "ANUAL",
        bg: "bg-light-orange",
        characteristics: [
            {
                name: "Incluye características plan empresarial mensual",
                icon: "fa-plus",
                color: "bg-purple",
            },
            {
                name: "Descuento más atractivo comparado con la tarifa mensual total.",
                icon: "fa-percent",
                color: "bg-green",
            },
            {
                name: "Soporte dedicado con un gerente de cuenta personal. Informes personalizados y análisis de uso anual para ayudar a optimizar procesos",
                icon: "fa-check",
                color: "bg-orange",
            },
        ],
    },
];