import ValidezFirma from "../../assets/ValidezFirma.jpg";

import Colombia from "../../assets/country/image_10.png";
import Bolivia from "../../assets/country/image_11.png";
import Brasil from "../../assets/country/image_12.png";
import Argentina from "../../assets/country/image_13.png";
import Chile from "../../assets/country/image_14.png";
import Canada from "../../assets/country/image_17.png";
import EeUu from "../../assets/country/image_18.png";
import Mexico from "../../assets/country/image_15.png";
// import Peru from "../../assets/country/image_16.png";

const countries = [
    { src: Colombia, name: "Colombia", type: "Firma electrónica" },
    { src: Bolivia, name: "Bolivia", type: "Firma electrónica" },
    { src: Brasil, name: "Brasil", type: "Firma electrónica y digital" },
    { src: Argentina, name: "Argentina", type: "Firma electrónica simple" },
    { src: Chile, name: "Chile", type: "Firma electrónica simple" },
    { src: Canada, name: "Canada", type: "Firma electrónica" },
    { src: EeUu, name: "Estados Unidos", type: "Firma electrónica" },
    { src: Mexico, name: "México", type: "Firma electrónica simple" },
    // { src: Peru, name: "Perú", type: "Firma electrónica avanzada" },
];

const ValidSignature = () => {
    return (
        <>
            <div className="row mt-4 mb-2">
                <h1 className="d-block text-center text-green font-teko">¿Tiene validez jurídica la firma electrónica?</h1>
                <h5 className="d-block text-center text-green font-lato">Sí, te contamos por qué y en qué países</h5>
            </div>
            <div className="row">
                <div className="col-12 col-lg-10 mx-auto">

                    <div className="row ps-3">
                        <div className="col-10 col-lg-7 mx-auto">
                            <div className="row">
                                <div className="col-12 col-lg-7">
                                    <p className="fs-5 mt-3">La validez jurídica de la firma electrónica es equivalente a la firma en papel. La integridad del documento se asegura mediante el uso del algoritmo SHA256, una función hash criptográfica desarrollada por la NSA. Además, los documentos están certificados con constancia y sello de tiempo según la normativa NOM-151, lo que garantiza la trazabilidad del documento.</p>
                                </div>
                                <div className="col-12 col-md-6 col-lg-5 mx-auto">
                                    <img src={ValidezFirma} width="100%" />
                                </div>
                            </div>
                        </div>
                        <div className="col-10 col-lg-5 mx-auto">
                            <table className="w-100">
                                <tbody>
                                    {
                                        countries.map(country => <tr key={country.name}>
                                            <td className="py-2"><img src={country.src} width="50px" /></td>
                                            <td className="px-2">{country.name}</td>
                                            <td><b>{country.type}</b></td>
                                        </tr>)
                                    }
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default ValidSignature;