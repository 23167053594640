import { useEffect, useRef, useState } from 'react';

const CardPricing = ({ title, frecuency, bg, price, anualPrice, characteristics, onSelected }) => {
    const [isScrolled, setIsScrolled] = useState(false);
    const componentRef = useRef();

    const handleScroll = () => {
        const scrollPosition = window.scrollY;
        const targetPosition = componentRef.current.offsetTop - 500;
        if (scrollPosition >= targetPosition) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div ref={componentRef}>
            <div className={`card-pricing animate__animated ${isScrolled ? "animate__fadeInUp" : "animate__fadeIn"}  ${frecuency === "ANUAL" ? "anual" : ""}`}>
                <div className={`header ${bg}`}>{title}</div>
                {
                    frecuency === "MONTHLY"
                        ?
                        <div className="body">
                            <div className="row">
                                <div className="col-10 mx-auto">
                                    <div className="row">
                                        <div className="col">
                                            <span className="fw-bold fs-6 me-2">COP</span><span className="fw-bold fs-4">{price}</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col text-gray">
                                            <span className="fw-bold fs-8 text-gray me-2">COP</span><span className="fw-bold fs-7">{anualPrice} al año</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col">
{/*                                             <button
                                                className="btn btn-sm bg-orange rounded-pill d-block mx-auto"
                                                onClick={onSelected}
                                            >
                                                Suscríbete
                                            </button> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3"></div>

                            {
                                characteristics.map(item => <div className="row" key={item.name}>
                                    <div className="col-1">
                                        <div className={`benefit-circle small ${item.isEspecial ? "bg-orange" : "bg-green"}`}>
                                            <i className="fa-solid fa-check text-white"></i>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <span className="text-gray fs-9">
                                            {item.name}
                                        </span>
                                    </div>
                                </div>)
                            }
                        </div>
                        :
                        <div className="body">
                            {
                                characteristics.map(item => <div className="row" key={item.name}>
                                    <div className="col-1">
                                        <div className={`benefit-circle small ${item.color}`}>
                                            <i className={`fa-solid ${item.icon} text-white`}></i>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <span className="text-gray fs-9">
                                            {item.name}
                                        </span>
                                    </div>
                                </div>)
                            }
                        </div>
                }

            </div>
        </div>

    )
}

export default CardPricing;